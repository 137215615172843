import { CardResume } from "./Card";

function Resume() {
  return (
    <div className="Resume-div">
        
        <CardResume
          contents={
            <>
              <h1 style={{margin:"0", marginTop:"10px"}}> Resume </h1>
              <iframe
                className="Resume"
                src="Resume - Web.pdf"
                title="resume"
                width="90%"
                height="800px"
              ></iframe>
            </>
          }
        ></CardResume>
      </div>
  );
}

export default Resume;