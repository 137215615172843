import React, {useState, useEffect} from 'react';
import './App.css';

const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return screenWidth;
  };

const Card = ({ title, content, image, hover=true, url}) => {
    const screenWidth = useScreenWidth();
    var brGradient;
    if (screenWidth < 600) {
        brGradient = `linear-gradient(to bottom, white 0%, transparent 5%), url(${image})`
        var height = "300px";
    } else {
        brGradient = `linear-gradient(to right, white 0%, transparent 5%), url(${image})`
    }

    const divStyle = {
        backgroundImage: brGradient, /* The image used */
        backgroundSize: "cover",
        width: "100%",
        borderRadius: "5px",
        backgroundPosition: "50% 20%",
        height: height,
    }

    let className = "Card";
    if (hover) {
        className += " card-hover";
    }

    if (url) {
        var link = <a href={url} className="Card-link">Learn more</a>;
    }
    
    return (
        <div className={className}>
            <p className="About-me-body">
                {content}
                {link}
            </p>
            {}
            <div className="image-stuff" style={divStyle}>
            </div>
        </div>
    );
};

const CardResume = ({contents}) => {    
    return (
        <div className="ResumeCard">
                {contents}
        </div>
    );
};


export default Card;
export {CardResume};
