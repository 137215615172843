import "./App.css";
// import Card, { CardResume } from "./Card";

function Photos() {
  return (
    <div className="Imgs">
      <h1> Lighting Design </h1>
      <div className="imgDiv">
      <img src="/Lighting/DSC03888.jpeg" alt="inheritTheWind" />        
      <img src="/Lighting/DSC04161.jpeg" alt="inheritTheWind" />
      <img src="/Lighting/DSC04180.jpeg" alt="inheritTheWind" />
      <img src="/Lighting/DSC04241.jpeg" alt="inheritTheWind" />
      <img src="/Lighting/DSC04552.jpeg" alt="inheritTheWind" />
      </div>
    </div>
  );
}

export default Photos;