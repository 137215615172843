import React, {useState} from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import Photos from "./Photos";
import Resume from "./Resume";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <header className="App-header">
        <div className="Left-header">
          {/* Hamburger Button */}
          <button className="hamburger" onClick={toggleMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </button>

          {/* Navigation Links */}
          <div className={`menu ${menuOpen ? "open" : ""}`}>
            <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
            <Link to="/photos" onClick={() => setMenuOpen(false)}>Lighting Design</Link>
            <Link to="/resume" onClick={() => setMenuOpen(false)}>Resume</Link>
          </div>
        </div>
        <div className="Middle-header">
          <div className="Middle-phone">
            <h3>ML</h3>
          </div>
          <div className="Middle-desk">
            <h1>Matthew Leopold</h1>
          </div>
        </div>
        <div className="Right-header">
          <a href="https://www.linkedin.com/in/matthew-leopold-b90686227/">
            <img src="linkedin.svg" className="Linkedin-logo" alt="linkedin" />
          </a>
          <a href="https://github.com/H-137">
            <img src="github.svg" className="Github-logo" alt="github" />
          </a>
        </div>
      </header>
      

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </Router>
  );
}

export default App;
